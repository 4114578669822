<template>
	<div class="app-container">
		<div class="content-title">政策详情</div>
		<div class="con-body">
			<el-form
				ref="form"
				:model="policyInfoDo"
				:rules="rules"
				label-width="120px"
			>
				<el-form-item label="政策名称" prop="policyName">
					{{ policyInfoDo.policyName }}
				</el-form-item>
				<el-form-item label="政策说明">
					{{ policyInfoDo.policyRemark }}
				</el-form-item>
				<el-form-item label="商户费率" required>
					<table class="rate-table" style="width:100%">
						<tr>
							<th style="width:210px">支付类型</th>
							<th style="width:120px">卡类型</th>
							<th style="width:160px">T0费率(%)</th>
							<th style="width:200px">T0费率范围(%)</th>
							<th style="width:160px">T1费率(%)</th>
							<th style="width:200px">T1费率范围(%)</th>
							<th style="width:160px">T1封顶(元)</th>
							<th style="width:200px">T1封顶范围(元)</th>
							<th style="width:160px">提现费(元)</th>
							<th style="width:200px">提现费范围(元)</th>
						</tr>
						<tr v-for="item in policyInfoRateDoList" :key="item.id">
							<!-- 大额扫码 -->
							<td>{{ item.payTypeCode | payType }}</td>
							<td>{{ item.cardType | formateCardType }}</td>
							<td>{{ item.t0Rate }}</td>
							<td>{{ item.t0MinRate }}-{{ item.t0MaxRate }}</td>
							<td>{{ item.t1Rate }}</td>
							<td>{{ item.t1MinRate }}-{{ item.t1MaxRate }}</td>
							<td>
								<template
									v-if="
										item.payTypeCode == 'POS' &&
											item.cardType == '2'
									"
								>
									封顶{{ item.t1TopFee }}
								</template>
								<template v-else>-</template>
							</td>
							<td>
								<template
									v-if="
										item.payTypeCode == 'POS' &&
											item.cardType == '2'
									"
								>
									{{ item.t1MinTopFee }}-{{
										item.t1MaxTopFee
									}}
								</template>
								<template v-else>-</template>
							</td>
							<td>
								<template
									v-if="
										item.payTypeCode == 'POS' &&
											item.cardType == '1'
									"
								>
									{{ item.cashFee }}
								</template>
								<template v-else>-</template>
							</td>
							<td>
								<template
									v-if="
										item.payTypeCode == 'POS' &&
											item.cardType == '1'
									"
								>
									{{ item.minCashFee }}-{{ item.maxCashFee }}
								</template>
								<template v-else>-</template>
							</td>
						</tr>
					</table>
				</el-form-item>
				<el-form-item label="成本设定" required>
					<table class="rate-table" style="width:100%">
						<tr>
							<th style="width:210px">支付类型</th>
							<th style="width:130px">卡类型</th>
							<th style="width:260px">T1成本</th>
							<th style="width:160px">T0成本</th>
							<th style="width:200px">提现费成本</th>
							<th style="width:260px">机构最高成本（T1）</th>
							<th style="width:200px">机构最高成本（T0）</th>
						</tr>
						<tr v-for="item in policyInfoCostDoList" :key="item.id">
							<td>{{ item.payTypeCode | payType }}</td>
							<td>{{ item.cardType | formateCardType }}</td>
							<td>
								{{ item.t1Rate }}%
								<template
									v-if="
										item.payTypeCode == 'POS' &&
											item.cardType == '2'
									"
								>
									(封顶{{ item.t1TopFee }}元)
								</template>
							</td>
							<td>{{ item.t0Rate }}%</td>
							<td>
								<template
									v-if="
										item.payTypeCode == 'POS' &&
											item.cardType == '1'
									"
								>
									{{ item.cashFee }}元
								</template>
								<template v-else>-</template>
							</td>
							<td>
								{{ item.t1MaxRate }}%
								<template
									v-if="
										item.payTypeCode == 'POS' &&
											item.cardType == '2'
									"
								>
									(封顶{{ item.t1MaxTopFee }}元)
								</template>
							</td>
							<td>{{ item.t0MaxRate }}%</td>
						</tr>
					</table>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>
<script>
import { PolicyApi } from "@/api";
export default {
	data() {
		return {
			policyInfoDo: "",
			policyInfoRateDoList: "",
			policyInfoCostDoList: "",
			rules: {
				policyName: [
					{
						required: true,
						message: "请输入政策名称",
						trigger: "blur"
					}
				]
			},
			loading: false
		};
	},
	created() {
		let policyNo = this.$route.query.policyNo;
		PolicyApi.policyInfo.detail(policyNo).then(res => {
			if (res.success) {
				this.policyInfoDo = res.data.policyInfoDo;
				this.policyInfoRateDoList = res.data.policyInfoRateDoList;
				this.policyInfoCostDoList = res.data.policyInfoCostDoList;
			}
		});
	},
	methods: {
		onSubmit() {
			this.$refs.form.validate(valid => {
				if (valid) {
					this.loading = true;
					let params = {
						policyInfoDo: this.policyInfoDo,
						policyInfoRateDoList: this.policyInfoRateDoList,
						policyInfoCostDoList: this.policyInfoCostDoList
					};
					PolicyApi.policyInfo.save(params).then(res => {
						console.log(res);
					});
				} else {
					return false;
				}
			});
			// let result = PolicyApi.save(this.rateFrom)
		}
	}
};
</script>
