var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-container"},[_c('div',{staticClass:"content-title"},[_vm._v("政策详情")]),_c('div',{staticClass:"con-body"},[_c('el-form',{ref:"form",attrs:{"model":_vm.policyInfoDo,"rules":_vm.rules,"label-width":"120px"}},[_c('el-form-item',{attrs:{"label":"政策名称","prop":"policyName"}},[_vm._v(" "+_vm._s(_vm.policyInfoDo.policyName)+" ")]),_c('el-form-item',{attrs:{"label":"政策说明"}},[_vm._v(" "+_vm._s(_vm.policyInfoDo.policyRemark)+" ")]),_c('el-form-item',{attrs:{"label":"商户费率","required":""}},[_c('table',{staticClass:"rate-table",staticStyle:{"width":"100%"}},[_c('tr',[_c('th',{staticStyle:{"width":"210px"}},[_vm._v("支付类型")]),_c('th',{staticStyle:{"width":"120px"}},[_vm._v("卡类型")]),_c('th',{staticStyle:{"width":"160px"}},[_vm._v("T0费率(%)")]),_c('th',{staticStyle:{"width":"200px"}},[_vm._v("T0费率范围(%)")]),_c('th',{staticStyle:{"width":"160px"}},[_vm._v("T1费率(%)")]),_c('th',{staticStyle:{"width":"200px"}},[_vm._v("T1费率范围(%)")]),_c('th',{staticStyle:{"width":"160px"}},[_vm._v("T1封顶(元)")]),_c('th',{staticStyle:{"width":"200px"}},[_vm._v("T1封顶范围(元)")]),_c('th',{staticStyle:{"width":"160px"}},[_vm._v("提现费(元)")]),_c('th',{staticStyle:{"width":"200px"}},[_vm._v("提现费范围(元)")])]),_vm._l((_vm.policyInfoRateDoList),function(item){return _c('tr',{key:item.id},[_c('td',[_vm._v(_vm._s(_vm._f("payType")(item.payTypeCode)))]),_c('td',[_vm._v(_vm._s(_vm._f("formateCardType")(item.cardType)))]),_c('td',[_vm._v(_vm._s(item.t0Rate))]),_c('td',[_vm._v(_vm._s(item.t0MinRate)+"-"+_vm._s(item.t0MaxRate))]),_c('td',[_vm._v(_vm._s(item.t1Rate))]),_c('td',[_vm._v(_vm._s(item.t1MinRate)+"-"+_vm._s(item.t1MaxRate))]),_c('td',[(
									item.payTypeCode == 'POS' &&
										item.cardType == '2'
								)?[_vm._v(" 封顶"+_vm._s(item.t1TopFee)+" ")]:[_vm._v("-")]],2),_c('td',[(
									item.payTypeCode == 'POS' &&
										item.cardType == '2'
								)?[_vm._v(" "+_vm._s(item.t1MinTopFee)+"-"+_vm._s(item.t1MaxTopFee)+" ")]:[_vm._v("-")]],2),_c('td',[(
									item.payTypeCode == 'POS' &&
										item.cardType == '1'
								)?[_vm._v(" "+_vm._s(item.cashFee)+" ")]:[_vm._v("-")]],2),_c('td',[(
									item.payTypeCode == 'POS' &&
										item.cardType == '1'
								)?[_vm._v(" "+_vm._s(item.minCashFee)+"-"+_vm._s(item.maxCashFee)+" ")]:[_vm._v("-")]],2)])})],2)]),_c('el-form-item',{attrs:{"label":"成本设定","required":""}},[_c('table',{staticClass:"rate-table",staticStyle:{"width":"100%"}},[_c('tr',[_c('th',{staticStyle:{"width":"210px"}},[_vm._v("支付类型")]),_c('th',{staticStyle:{"width":"130px"}},[_vm._v("卡类型")]),_c('th',{staticStyle:{"width":"260px"}},[_vm._v("T1成本")]),_c('th',{staticStyle:{"width":"160px"}},[_vm._v("T0成本")]),_c('th',{staticStyle:{"width":"200px"}},[_vm._v("提现费成本")]),_c('th',{staticStyle:{"width":"260px"}},[_vm._v("机构最高成本（T1）")]),_c('th',{staticStyle:{"width":"200px"}},[_vm._v("机构最高成本（T0）")])]),_vm._l((_vm.policyInfoCostDoList),function(item){return _c('tr',{key:item.id},[_c('td',[_vm._v(_vm._s(_vm._f("payType")(item.payTypeCode)))]),_c('td',[_vm._v(_vm._s(_vm._f("formateCardType")(item.cardType)))]),_c('td',[_vm._v(" "+_vm._s(item.t1Rate)+"% "),(
									item.payTypeCode == 'POS' &&
										item.cardType == '2'
								)?[_vm._v(" (封顶"+_vm._s(item.t1TopFee)+"元) ")]:_vm._e()],2),_c('td',[_vm._v(_vm._s(item.t0Rate)+"%")]),_c('td',[(
									item.payTypeCode == 'POS' &&
										item.cardType == '1'
								)?[_vm._v(" "+_vm._s(item.cashFee)+"元 ")]:[_vm._v("-")]],2),_c('td',[_vm._v(" "+_vm._s(item.t1MaxRate)+"% "),(
									item.payTypeCode == 'POS' &&
										item.cardType == '2'
								)?[_vm._v(" (封顶"+_vm._s(item.t1MaxTopFee)+"元) ")]:_vm._e()],2),_c('td',[_vm._v(_vm._s(item.t0MaxRate)+"%")])])})],2)])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }